<div class="container">
  <div *ngIf="!onlineStatus" class="offline-notification">
    Estás fuera de línea. Algunas funcionalidades pueden no estar disponibles.
  </div>

  <div
    *ngIf="showNotification"
    class="message-notification"
    (click)="handleNotificationClick()"
  >
    <div class="notification-content">
      <i class="notification-icon">📨</i>
      <div class="notification-text">
        <strong>Nuevo mensaje</strong>
        <p>Has recibido un nuevo mensaje</p>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
