import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

export interface SocketEvent {
  event: string;
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private readonly socket: Socket;
  private readonly connectionStatus = new BehaviorSubject<boolean>(false);
  public connectionStatus$ = this.connectionStatus.asObservable();

  constructor() {
    this.socket = io(environment.apiUrl, {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 10000,
    });

    this.initializeSocketListeners();
  }

  private initializeSocketListeners(): void {
    this.socket.on('connect', () => {
      console.log('Socket conectado');
      this.connectionStatus.next(true);
    });

    this.socket.on('disconnect', () => {
      console.log('Socket desconectado');
      this.connectionStatus.next(false);
    });

    this.socket.on('connect_error', (error) => {
      console.error('Error de conexión:', error);
      this.connectionStatus.next(false);
    });
  }

  public emit(event: string, data?: any): void {
    if (this.socket.connected) {
      this.socket.emit(event, data);
    } else {
      console.warn('Socket no conectado. No se puede emitir:', event);
    }
  }

  public on<T>(event: string): Observable<T> {
    return new Observable((observer) => {
      try {
        this.socket.on(event, (data: T) => {
          observer.next(data);
        });
      } catch (error) {
        observer.error(error);
      }

      return () => {
        this.socket.off(event);
      };
    });
  }

  public connect(): void {
    if (!this.socket.connected) {
      this.socket.connect();
    }
  }

  public disconnect(): void {
    if (this.socket.connected) {
      this.socket.disconnect();
    }
  }

  public isConnected(): boolean {
    return this.socket.connected;
  }

  ngOnDestroy() {
    this.disconnect();
  }
}
