import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { NetworkService } from './core/services/network.service';
import { Router, RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';
import { SocketService } from './core/services/socket.service';
import { ChatDrawerComponent } from './components/chat-drawer/chat-drawer.component';

export interface WebsocketMessage {
  isUnread: boolean;
  chatId: string;
  orderId?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, RouterOutlet, ChatDrawerComponent],
})
export class AppComponent {
  title = 'gobakery-pwa';
  onlineStatus: boolean | undefined;
  user: any = JSON.parse(sessionStorage.getItem('user')!);
  activeNotification: WebsocketMessage | null = null;
  chatId: string = '';
  showNotification = false;
  private networkStatusSubscription: Subscription | undefined;
  private websocketSubscription: Subscription | undefined;

  constructor(
    private readonly networkService: NetworkService,
    private readonly socketService: SocketService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.networkStatusSubscription =
      this.networkService.onlineStatus$.subscribe((status) => {
        this.onlineStatus = status;
      });

    const role =
      this.user?.profile === 'ADMIN' || this.user?.profile === 'SUPERADMIN'
        ? 'admin'
        : 'client';

    if(this.websocketSubscription) {
      this.websocketSubscription.unsubscribe();
    }

    this.websocketSubscription = this.socketService
      .on<WebsocketMessage>(`unreadMessage-${role}`)
      .subscribe((data) => {
        this.activeNotification = data;
        this.showNotification = true;
      });
  }

  handleNotificationClick() {
    if (!this.activeNotification) return;

    if (this.activeNotification.orderId) {
      this.router.navigate(['/home/order-summary'], {
        queryParams: { orderId: this.activeNotification.orderId },
      });
    }

    this.showNotification = false;
    this.activeNotification = null;
  }

  ngOnDestroy() {
    this.networkStatusSubscription?.unsubscribe();
  }
}
